.background{
  background-color:rgba(94, 92, 91 , 0.4);

}

.grid{
	text-align: center;
	border-radius: 20px;

}
.HomeText{
  text-align: center;
}

.CardText{
  text-align: center;
  font-size: inherit +2;

}

.CardTitle{


}

.HeroText{
	color: white;
	opacity: 1;
	padding-top: 10px;
	padding-bottom: 10px;

}

.KontaktText{
  padding-left: 5%;
  padding-top: 2.5%;
  padding-bottom: 2.5%;
  padding-right: 1%;

}

.Picture{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 240px;
  height: 240px;

}

.SocialLinks {
	color: white;
	font-size: 5em;
  display: flex;
  text-align: center;
	margin: auto;

}

.NavLink{
  color: white !important;

}

.Footer{
  color:white;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #404040;

}
